<template  >

  <div  >

  </div>
</template>



<script>




export default {
  name: 'About',
  components: {

  },
  destroyed() {

  },
  mounted() {



  },

  data() {
    return {


    };
  },
  methods: {
  },
}
</script>

<style >



</style>
